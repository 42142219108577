// OBJETO GLOBAL FORM VARIABLES
const formData = {
  nombre: '',
  telefono: '',
  correo: '',
  departamento: '',
  asunto: '',
  comentarios: '',
  reCaptchaResponse: ''
}


// INPUTS QUERY SELECTOR
const nombre = document.querySelector('#nombre');
const telefono = document.querySelector('#telefono');
const correo = document.querySelector('#correo');
const departamento = document.querySelector('#departamento');
const asunto = document.querySelector('#asunto');
const comentarios = document.querySelector('#comentarios');

// Form
const formularioContacto = document.querySelector("#form-contacto");

// Subtmit Btn
const submitBtn = document.querySelector('#submit-btn');

// Renew Lead
const renewLead = document.querySelector('#renew-lead');
const renewLeadBtn = document.querySelector('#reset-form');

const resetFormData = () => {
  formData.nombre = '';
  formData.telefono = '';
  formData.correo = '';
  formData.asunto = '';
  formData.comentarios = '';
  formData.departamento = '';
  formData.reCaptchaResponse = '';
}

const asignarFormData = (dataVar, dataItem) => {
  dataVar.addEventListener('input', (event) => {
    const value = event.target.value;

    if(dataItem === 'nombre'){
      formData.nombre = value;
    }
    else if(dataItem === 'telefono'){
      formData.telefono = value;
    }
    else if(dataItem === 'correo'){
      formData.correo = value;
    }
    else if(dataItem === 'asunto'){
      formData.asunto = value;
    }
    else if(dataItem === 'comentarios'){
      formData.comentarios = value;
    }
    else{
      return;
    }

    // console.log(formData);
  });

  const asignacionDirecta = () => {
    if(dataItem === 'departamento'){
      formData.departamento = dataVar.value;
    } 
    else if (dataItem === 'reCaptchaResponse'){
      formData.reCaptchaResponse = dataVar.value;
    }
    else{
      return;
    }
    // console.log(formData);
  }
  asignacionDirecta();
}

asignarFormData(nombre, 'nombre');
asignarFormData(telefono, 'telefono');
asignarFormData(correo, 'correo');
asignarFormData(asunto, 'asunto');
asignarFormData(comentarios, 'comentarios');


// Ingresa solo numeros a los inputs de Telefono 0 -> 9
const soloNumeros = (evt) => {
  evt = (evt) ? evt : window.event;
  let charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

// Collapse Picker
const collapsePicker = () => {
  const collapseSelect = document.querySelector('#collapsePicker');
  const collapseInstance = new bootstrap.Collapse(collapseSelect, {
    toggle: false,
    show: true,
    hide: true
  });

  const options = document.querySelectorAll('#collapsePicker ul li');

  collapseSelect.addEventListener('show.bs.collapse', () => {
    options.forEach( (option) => {
      option.addEventListener('click', () => {
        collapseInstance.hide();
        departamento.setAttribute('value', option.dataset.departamento);
        asignarFormData(departamento, 'departamento');
      });
    });
  });
}
collapsePicker();

const validatePathResponse = () => {
  const pathPhp = './includes/tracking/process-form.php';
  // const pathJs = './includes/tracking/process-form.php';

  // sendForm(pathPhp, pathJs);
  sendForm(pathPhp);
}

// ReCaptcha
const validateRecaptcha = () => {
  const mgsRecaptcha = document.querySelector('#msgSubmitRecaptcha');

  if (grecaptcha && grecaptcha.getResponse(0).length > 0) {
    mgsRecaptcha.textContent = '';
    formData.reCaptchaResponse = grecaptcha.getResponse(0);
    // console.log(grecaptcha.getResponse(0));
    
    mgsRecaptcha.classList.remove('input-error');
    return true;
  } else {
    mgsRecaptcha.textContent = 'Confirma que no eres un robot';
    // console.log(grecaptcha.getResponse(0));

    mgsRecaptcha.classList.add('input-error');
    return false;
  }
}

$("#submit-btn").mouseover(function() {
  validateRecaptcha();
});

window.onload = () => {
  // create the pristine instance
  const pristine = new Pristine(formularioContacto);

  formularioContacto.addEventListener('submit', (event) => {
    event.preventDefault();
    
    // check if the form is valid
    const valid = pristine.validate(); // returns true or false

    if(valid && validateRecaptcha()){
      // sendForm();
      validatePathResponse();
    }
    else{
      // console.log(valid);
      return false;
    }

  });
};


// const sendForm = (pathPhp, pathJs) => {
// const sendForm = (pathPhp) => {
//   submitform.addEventListener('click', (event) => {
//     //Prevent the event from submitting the form, no redirect or page reload
//     event.preventDefault();
//     validarFormSend();
//     blurFormContacto();
//     // console.log(pathPhp+' '+pathJs);
    
//     // console.log(contadorAlertasInputs());
//     if (contadorAlertasInputs() === 0) {
//       mostrarCompletarCampos('', true, padreSubmitForm);
//       submitform.classList.add('d-none');

//       $("#formulario-contacto").addClass("animacion-form");
//       if($("#formulario-contacto").hasClass("animacion-form")) {
//         setTimeout( function(){ 
//           $("#formulario-contacto").addClass("d-none");
//           $('#ancla-seccion-form').scrollTop(0);
//           $("#procesando-datos").removeClass("d-none");
//           $("#procesando-datos").addClass("d-block suavizar-opacidad");
//         }, 700);
//       }
//       else{
//         $("#formulario-contacto").removeClass("animacion-form");
//         $("#formulario-contacto").removeClass("d-none");
//       }

//       // postData(formData, pathPhp, pathJs);
//       postData(formData, pathPhp);
//     }
    
//     else {
//       console.log('Hay un error en dev');
//     }
//   });
// } 

const sendForm = (pathPhp) => {
  // mostrarCompletarCampos('', true, padreSubmitForm);
  submitBtn.classList.add('d-none');

  $("#form-contacto").addClass("animacion-form");
  if($("#form-contacto").hasClass("animacion-form")) {
    setTimeout(() => { 
      $("#form-contacto").addClass("d-none");
      // $('#ancla-seccion-form').scrollTop(0);
      $("#procesando-datos").removeClass("d-none");
      $("#procesando-datos").addClass("d-block suavizar-opacidad");
    }, 700);
  }
  else{
    $("#form-contacto").removeClass("animacion-form");
    $("#form-contacto").removeClass("d-none");
  }

  // postData(formData, pathPhp, pathJs);
  postData(formData, pathPhp);
} 

// async function postData(formattedFormData, pathPhp, pathJs){
async function postData(formattedFormData, pathPhp){
  const response = await fetch(pathPhp, {
    method: 'POST',
    body: JSON.stringify(formattedFormData)
  });

  const data = await response.text();
  // console.log(data);
  // const data = await response.json();

  //This should later print out the values submitted through the form
  // console.log(data);
  if (data === "Completado") {
    const defaultForm = () => {
      renewLeadBtn.addEventListener('click', () => {
        formularioContacto.reset();
        departamento.setAttribute('value','');
    
        // Reset object data
        resetFormData();
    
        // Reset reCaptcha
        grecaptcha.reset();
        
        submitBtn.classList.remove('d-none');

        // window.location.href = pathJs;
        // console.log(formData);
  
        $("#form-contacto").removeClass("animacion-form");
        $("#form-contacto").removeClass("d-none");

        renewLead.classList.add('d-none');
      });
    }

    setTimeout(() => {
      $("#procesando-datos").removeClass("d-block suavizar-opacidad");
      $("#procesando-datos").addClass("d-none");

      renewLead.classList.remove('d-none');
      defaultForm(); 
    }, 1500);

  } else {
    console.log('Algo salio muy mal :(');
  }
}
